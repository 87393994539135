export enum Action {
  ADD = 'add',
  DELETE = 'delete',
  UPDATE = 'update',
  QUERY = 'query',
  IMPORT = 'import',
  EXPORT = 'export',
}

export interface Permission {
  /* 权限ID */
  id: string | number;
  /* 权限归属的角色 */
  roleId?: string | number;
  /* 权限名称 */
  name: string;
  /* 权限显示的名字 */
  label?: string;
  /* 权限拥有的操作 [增,删,改,查] */
  actions?: Action[];
}

export interface Role {
  /* 角色ID */
  id: string | number;
  /* 组织ID */
  org_id: number;
  /* 角色名称 */
  name: string;
  /* 角色描述 */
  desc: string;
  /* 角色绑定的权限 */
  permissions?: Permission[];
}

export interface UserState {
  token?: string;
  username: string;
  nickname: string;
  avatar: string;
  sessionid2: string;
  role?: Role;
  allowRouters: [];
  org_list: [];
  current_org: {
    [key: string]: any;
  };
  custome_data_user: {
    [key: string]: any;
  };
  extra: {
    [key: string]: any;
  };

  [key: string]: any;
}

export interface PersonType {
  /* 个人类别ID */
  id: string | number;
  /* 名称 */
  name: string;
  /* 描述名称 */
  description: string;
}

export interface ReferenceNumberType {
  /* 类别ID */
  id: string | number;
  /* 名称 */
  name: string;
  /* 描述名称 */
  description: string;
}

export interface Address {
  id: string | number /* 类别ID */;
  street: string /* 街道 */;
  city: string /* 城市 */;
  state: string /* 州/省 */;
  country_id: number /* 国家 */;
  postcode: string /* 邮编 */;
}

export interface Country {
  id: string | number /* 类别ID */;
  code: string /* 国家代码 */;
  name: string /* 国家名称 */;
  currency: string /* 币种 */;
  nationality: string /* 国籍 */;
}

export interface Country {
  id: string | number /* 类别ID */;
  code: string /* 国家代码 */;
  name: string /* 国家名称 */;
  currency: string /* 币种 */;
  nationality: string /* 国籍 */;
}
