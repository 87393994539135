
import { defineComponent, reactive, ref, PropType } from 'vue';
import { useForm } from 'ant-design-vue/es/form';
import { Role, Permission, Action } from '@/store/modules/user/typing';

type Tag = {
  key: string;
  describe: string;
};

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    model: {
      type: Object as PropType<Role | null>,
      default: () => null,
    },
  },
  emits: ['cancel'],
  setup() {
    const loading = ref(false);
    const modelRef = reactive({
      id: undefined,
      roleName: undefined,
      describe: undefined,
      permissions: [],
    });
    const rulesRef = reactive({
      id: [{ required: true }],
      roleName: [{ required: true }],
    });
    // mock role permissions
    const rolePermissions = ref<Permission[]>([
      { id: 'roleManage', name: '角色管理', actions: [Action.ADD, Action.UPDATE] },
      { id: 'userManage', name: '用户管理', actions: [Action.ADD, Action.UPDATE, Action.QUERY] },
    ]);
    const tags: Tag[] = [
      { key: 'add', describe: '新增' },
      { key: 'update', describe: '修改' },
      { key: 'delete', describe: '删除' },
      { key: 'query', describe: '查询' },
      { key: 'import', describe: '导入' },
      { key: 'export', describe: '导出' },
    ];
    const selectedTags = ref<string[]>([]);
    const { validateInfos, resetFields } = useForm(modelRef, rulesRef);

    const handleSubmit = () => {};
    const handleChange = (actions: Action[], tag: Tag, checked: boolean) => {
      if (checked) {
        actions.push(tag.key as Action);
      } else {
        const index = actions.findIndex(item => item === tag.key);
        actions.splice(index, 1);
      }
    };
    return {
      ...formLayout,

      loading,

      modelRef,
      validateInfos,
      resetFields,

      handleSubmit,
      handleChange,
      tags,
      selectedTags,

      rolePermissions,
    };
  },
});
