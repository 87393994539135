
import { defineComponent, ref } from 'vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { getRoles } from '@/api/user/role';
import { Pagination, TableColumn, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import RoleModal from './role-modal.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'RoleList',
  setup() {
    const { t } = useI18n();
    const roleModalRef = ref({});
    const baseColumns: TableColumn[] = [
      {
        title: '编号',
        dataIndex: 'id',
      },
      {
        title: '角色名称',
        dataIndex: 'name',
      },
      {
        title: '描述',
        dataIndex: 'describe',
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        slots: { customRender: 'action' },
      },
    ];
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, { checkAll: true, needRowIndex: false });
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();

    // 表格数据和加载
    const { reload, setPageInfo, context: state } = useFetchData(getRoles, {
      current: 1,
      pageSize: 10,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
    });
    const handleTableChange = ({ current, pageSize }: Pagination, filters: TableFilters) => {
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
      });

      reload();
    };
    // 新增修改表单
    const modalVisible = ref<boolean>(false);

    return {
      state,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,

      // 表单
      modalVisible,

      roleModalRef,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    RoleModal,
  },
});
